
class InViewItem {

    constructor( el ) {
        this.el = el
        this.y  = 0
        this.inview = false
    }

    refresh( y ) {
        this.y = this.el.getBoundingClientRect().top + y
    }

    watch( scrollY ) {
        if ( this.inview ) return
        if ( scrollY > this.y ) {
            this.inview = true
            this.el.classList.add('in-view')
        }
    }

    reset() {
        this.el.classList.remove('in-view')
        this.inview = false
    }
}

module.exports = InViewItem