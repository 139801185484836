const {TimelineMax, TweenMax} = require('gsap')
const InViewItem = require('./lib/InViewItem')
const clamp = require('clamp')
const loop = require('raf-loop')
const detectIE = require('./lib/detectIE')

require( 'gsap/src/uncompressed/plugins/ScrollToPlugin' )

class App  {

    constructor() {

        this.elapsed = 0
        this.watchScroll = true
        this.engine = loop( this.tick.bind( this ) )
        this.mouse = [0, 0]
        this.winSize = [window.innerWidth, window.innerHeight]
        this.onscroll = this.onscroll.bind( this )
        this.onresize = this.onresize.bind( this )
        this.mainVisual = document.querySelector('.featured-image')
        this.interstitial = document.querySelector('.interstitial .inner')
        this.spanningBackground = document.querySelector('.interstitial .background')

        this.inViewItems = Array.from(document.querySelectorAll('.in-view-element')).map( ( el ) => new InViewItem( el ) )

        window.addEventListener('scroll', this.onscroll)
        window.addEventListener('resize', this.onresize)

        this.intro()
        this.onresize()
        this.onscroll()

    }

    startAdjustingSpanningBackground() {
        let count = 20
        let timeout = setTimeout( () => {
            this.adjustSpanningBackground()
            count--
            if ( count == 0 ) {
                clearTimeout( timeout )
            }
        }, 1000 )
    }

    tick( dt ) {
        this.elapsed += dt
    }

    onresize() {
        this.winSize[ 0 ] = window.innerWidth
        this.winSize[ 1 ] = window.innerHeight
        this.refresh()
        this.onscroll()
    }

    onscroll( e ) {
        if ( !this.watchScroll ) return
        let y = window.pageYOffset
        this.inViewItems.forEach( ( item ) => {
            item.watch( y + this.winSize[1] - this.winSize[1] * 0.1 )
        } )
    }

    refresh() {
        let y = window.pageYOffset
        this.inViewItems.forEach( ( item ) => {
            item.refresh( y )
        } )
        this.adjustSpanningBackground()
    }


    adjustSpanningBackground() {
        let mainVisualBBox = this.mainVisual.getBoundingClientRect()
        let topA = mainVisualBBox.top
        let topB = this.interstitial.getBoundingClientRect().top
        let newHeight = topB - topA - (mainVisualBBox.height > 500 ? 200 : 0)
        let offset = this.interstitial.offsetTop + this.interstitial.clientHeight * 0.48
        this.spanningBackground.style.height = newHeight + 'px'
        this.spanningBackground.style.top = -newHeight + offset + 'px'
    }

    intro () {
        let tl = new TimelineMax()
        let headerBBox = document.querySelector('header').getBoundingClientRect() 
        let offset = this.winSize[1] * 0.5 - (headerBBox.top + headerBBox.height * 0.5)
        tl.set( 'body', { overflow: 'hidden' })
        tl.set( '.intro', { opacity: 0, y: offset } )
        tl.set( '.interstitial .background', { opacity: 0 } )
        tl.set( 'header', { y: offset})
        tl.set( 'header .col', { opacity: 0.0, y: 20 })
        tl.staggerTo( 'header .col', 1.0, { y: 0, opacity: 1.0, delay: 0.5 }, 0.1 )
        tl.to( 'header', 1.5, { y: 0, ease: 'Expo.easeInOut', delay: 1.0 } )
        tl.addLabel( 'a', '-=' + 0.8  )
        tl.set( 'body', { overflow: '' })
        tl.to( '.intro', 1.0, { opacity: 1.0 }, 'a'  )
        tl.to( '.intro', 1.5, { y: 0, ease: 'Expo.easeOut', onComplete: () => {
            tl.to( '.interstitial .background', 0.3, { opacity: 1 } )
            this.startAdjustingSpanningBackground()
        } }, 'a'  )

    }
}

// IE madness
const from = require('array.from')
if ( !Array.from ) from.shim()

let result = detectIE()
if ( result && result <= 9 ) {
    document.documentElement.className = ''
} else {
    let app = new App()
    app.engine.start()
}

